<template>

	<b-navbar toggleable="md" type="light" variant="light" style="color: #ffc904" fixed="top">
		<b-navbar-toggle target="nav_collapse"></b-navbar-toggle>
		<b-navbar-brand v-if="this.isSetPassword == false">
			<router-link to="/" style="color:black">
				<img src="/images/TransferConnect-App-Logo-Horizontal-BY-.png" style="height: 35px;" ></router-link>
		</b-navbar-brand>
		<b-navbar-brand v-if="this.isSetPassword == true">
			<img src="/images/TransferConnect-App-Logo-Horizontal-BY-.png" style="height: 35px;" >
		</b-navbar-brand>
		<b-collapse is-nav id="nav_collapse">

			<b-navbar-nav v-show="this.isLoggedIn == true">
				<b-nav-item-dropdown right>
					<template slot="button-content"><em>Coach</em></template>

					<b-dropdown-item><router-link to="/students">Students</router-link></b-dropdown-item>
					<b-dropdown-item><router-link to="/events">Events</router-link></b-dropdown-item>

				</b-nav-item-dropdown>
			</b-navbar-nav>

			<b-navbar-nav v-show="this.isLoggedIn == true && this.$cookies != undefined && this.$cookies.get('isAdmin') == 'true'">
				<b-nav-item-dropdown right>
					<template slot="button-content"><em>Content</em></template>

					<b-dropdown-item><router-link to="/levels">Levels</router-link></b-dropdown-item>
					<b-dropdown-item><router-link to="/quests">Quests</router-link></b-dropdown-item>
					<b-dropdown-item><router-link to="/workshops">Workshops</router-link></b-dropdown-item>

					<b-dropdown-divider/>
					<b-dropdown-item><router-link to="/contentlessons">Content Lessons</router-link></b-dropdown-item>
					<b-dropdown-item><router-link to="/media">Media</router-link></b-dropdown-item>
					<b-dropdown-item><router-link to="/checklists">Checklists</router-link></b-dropdown-item>

					<b-dropdown-divider/>
					<b-dropdown-item><router-link to="/scripts">Scripts</router-link></b-dropdown-item>

					<b-dropdown-divider/>
					<b-dropdown-item><router-link to="/majors">Majors</router-link></b-dropdown-item>
					<b-dropdown-item><router-link to="/achievements">Achievements</router-link></b-dropdown-item>
					<b-dropdown-item><router-link to="/pois">POIs</router-link></b-dropdown-item>

					<b-dropdown-divider/>
					<b-dropdown-item><router-link to="/settings">Settings</router-link></b-dropdown-item>

				</b-nav-item-dropdown>
			</b-navbar-nav>

			<b-navbar-nav v-show="this.isLoggedIn == true && this.$cookies != undefined && this.$cookies.get('isAdmin') == 'true'" >
				<b-nav-item-dropdown right>
					<template slot="button-content"><em>Admin</em></template>

					<b-dropdown-item><router-link to="/users">Users</router-link></b-dropdown-item>
					<b-dropdown-item><router-link to="/reports">Reports</router-link></b-dropdown-item>
					<b-dropdown-item><router-link to="/broadcasts">Broadcasts</router-link></b-dropdown-item>
					<b-dropdown-item><router-link to="/administration">Administration</router-link></b-dropdown-item>

				</b-nav-item-dropdown>
			</b-navbar-nav>

			<b-navbar-nav class="ml-auto">

			<b-nav-text>
			{{welcomeMessage}}
			</b-nav-text>
			&nbsp; &nbsp; &nbsp;

			<b-nav-item-dropdown right v-show="this.isSetPassword == false">
				<template slot="button-content"><em>User</em></template>

				<b-dropdown-item v-show="this.isLoggedIn == true"><router-link to="/profile">Profile</router-link></b-dropdown-item>
				<b-dropdown-item v-show="this.isLoggedIn == true"><router-link to="/logout">Log Out</router-link></b-dropdown-item>
				<b-dropdown-item v-show="this.isLoggedIn == false"><router-link to="/login">Log In</router-link></b-dropdown-item>

			</b-nav-item-dropdown>

			</b-navbar-nav>

		</b-collapse>
	</b-navbar>

</template>

<style scoped src="../public/css/main.css"/>

<script>

	import Vue from 'vue';
	import { EventBus } from '@/event-bus';

	export default {
		name: 'Header',
		data () {
			return {
				welcomeMessage: "",
				isLoggedIn: false,
				isAdmin: false,
				isSetPassword: false,
			}
		},
		mounted () {

			EventBus.$header = this;

			EventBus.$on('logout_occurred', function(data) {

	            Vue.$cookies.remove('api_token');
	            Vue.$cookies.remove('email');
	            Vue.$cookies.remove('isAdmin');
	            Vue.$cookies.remove('userID');

	            // reload menu
	            EventBus.$header.doUpdate();
			});

			EventBus.$on('login_occurred', function(data) {

	            Vue.$cookies.set('api_token', data.api_token);
	            Vue.$cookies.set('isAdmin', data.isAdmin);
	            Vue.$cookies.set('email', data.email);
	            Vue.$cookies.set('userID', data.userID);

				//var today = new Date(new Date().getTime() + 1*60000);
				//var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
				//console.log('cookies expire at: ' + time);

	            // reload menu
	            EventBus.$header.doUpdate();
			});

			EventBus.$on('extend_timeout', function(data) {

	            Vue.$cookies.set('api_token', Vue.$cookies.get('api_token'));
	            Vue.$cookies.set('isAdmin', Vue.$cookies.get('isAdmin'));
	            Vue.$cookies.set('email', Vue.$cookies.get('email'));
	            Vue.$cookies.set('userID', Vue.$cookies.get('userID'));

				//var today = new Date(new Date().getTime() + 1*60000);
				//var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
				//console.log('cookies expire at: ' + time);

	            // reload menu
	            EventBus.$header.doUpdate();
			});

			this.doUpdate();
		},
		methods: {
			doUpdate() {

				this.isLoggedIn = Vue.$cookies != undefined && Vue.$cookies.get('api_token') != null;
				this.isAdmin = Vue.$cookies != undefined && Vue.$cookies.get('isAdmin') == 'true';
				this.isSetPassword = window.location.pathname === '/setPassword';

				if (this.isAdmin == null)
					this.isAdmin = false;

				var email = '';
				if (Vue.$cookies != undefined)
					email = Vue.$cookies.get('email');
				if (!email || email == "") {
					this.welcomeMessage = "";
				}
				else
					this.welcomeMessage = "Welcome, " + email;
			},
		}
	};
</script>

<style scoped>
	.navbar.navbar-light.bg-light{
		background-color: #ffc904!important;
	}
</style>
