<template>
  <div class="footer">
    <a href="https://www.ucf.edu" style="line-height: 50px; color: #ffc904">UCF</a>
  </div>
</template>

<script>
export default {
  name: 'Footer'
};
</script>

<style scoped>
	.footer {
			text-align: center;
	    background: #000000;
	    position: fixed;
	    left: 0;
	    right: 0;
	    bottom: 0;
	    height: 50px;
	    z-index:1000;
	}
</style>
