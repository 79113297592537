<template>
  <div class="Main" style="padding: 20px">
    <br/>
    <table>
        <tr>
          <td align='Right'><b>Active Students:</b></td>
          <td>{{studentCount}}</td>
        </tr>
    </table>
    <br>&nbsp;
    <div style="width: 75%">
      <status-chart v-if="loaded" :chartdata="chartdata" :options="options" ref="chart"/>
    </div>
    <br>&nbsp;
    <br>&nbsp;
    <br>&nbsp;
  </div>
</template>

<script>

// @ is an alias to /src
import StatusChart from '@/charts/StatusChart.vue';
import Vue from 'vue';

  export default {
    name: 'Main',
    components: {
      'status-chart': StatusChart,
    }
    ,
    data () {
        return {
            studentCount: '',
            loaded: false,
            chartdata: {
              labels: [],
              datasets:
                [ {
                  label: 'Student Progress',
                  backgroundColor: '#ffc904',
                  data: []
                } ] },
            distributions: [],
            levels: [],
            quests: [],
        }
    },
    mounted() {
        this.redisplay();
    },
    methods: {
        redisplay() {
          this.loaded = false;

          this.$axios.get(this.$backendUrl + "/students/stats?access_token=" + Vue.$cookies.get('api_token')).then(result => {
              this.studentCount = result.data.studentCount;

              this.$axios.get(this.$backendUrl + "/students/stats2?access_token=" + Vue.$cookies.get('api_token')).then(result => {
                  this.distributions = result.data;

                this.$axios.get(this.$backendUrl + "/levels?access_token=" + Vue.$cookies.get('api_token')).then(result => {
                    this.levels = result.data;

                    this.$axios.get(this.$backendUrl + "/quests?access_token=" + Vue.$cookies.get('api_token')).then(result => {
                        this.quests = result.data;

                        try {

                          this.composeData();

                          this.options = {
                            maintainAspectRatio: false,
                            legend: {
                                display: false,
                             },
                             scales: {
                              yAxes: [{
                                ticks: {
                                  beginAtZero: true,
                                  suggestedMax: Math.floor(this.studentCount / 2),
                                  precision: 0,
                                },
                                gridLines: { color: "rgba(0, 0, 0, 0)", },
                              }],
                              xAxes: [{
                                gridLines: { color: "rgba(0, 0, 0, 0)", },
                              }],
                            },
                            responsive: true,
                            onClick: (e, tooltipItems) => {
                              if (tooltipItems !== 'undefined' && tooltipItems.length > 0) {
                                this.$router.push(
                                  { name: 'students', params: { questIndex : tooltipItems[0]._index } });
                              }
                            },
                          };
                          this.loaded = true;
                        }
                        catch (e) {
                          console.error(e)
                        }
                      }, error => {
                          console.error(error);
                      });
                  }, error => {
                      console.error(error);
                  });
              }, error => {
                  console.error(error);
              });
          }, error => {
              console.error(error);
          });
        },
        composeData() {

          // get level info
          this.levels = this.levels.sort(compareLevels);
          var levelQuests = [];
          var i = 0;
          for (i = 0; i < this.levels.length; i++) {
            levelQuests[i] = 0;
          }
          for (i = 0; i < this.quests.length; i++) {
            var levelIndex = 0;
            for (levelIndex = 0; levelIndex < this.levels.length; levelIndex++) {
              if (this.levels[levelIndex].id == this.quests[i].levelID) {
                levelQuests[levelIndex] += 1;
                break;
              }
            }
          }

          // construct data
          var labels = ['Starting'];
          var labelCompare = ['0/0'];
          var data = [0];
          var counter = 1;
          for (i = 0; i < levelQuests.length; i++) {
            var j = 0;
            for (j = 0; j < levelQuests[i]; j++) {
              labels[counter] = 'L' + (i + 1) + 'Q' + (j + 1);
              labelCompare[counter] = (i+1) + ',' + (j+1);
              data[counter] = 0;
              counter ++;
            }
          }

          var total = 0;
          for (i = 0; i < this.distributions.length; i++) {
            for (j = 0; j < labelCompare.length; j++) {
              if (labelCompare[j] == this.distributions[i]._id) {
                total += this.distributions[i].count;
                data[j] += this.distributions[i].count;
              }
            }
          }
          data[0] = this.studentCount - total;

          this.chartdata.labels = labels;
          this.chartdata.datasets[0].data = data;
        }
    }
  };

function compareLevels(a,b) {
  if (a.sequence < b.sequence)
    return -1;
  if (a.sequence > b.sequence)
    return 1;
  return 0;
}

</script>
