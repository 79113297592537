import Vue from 'vue'
import App from './App.vue'
import router from './router'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import axios from 'axios'
import cookies from 'vue-cookies'
import VueBreadcrumbs from 'vue-2-breadcrumbs'


// configuration settings

// local
// Vue.prototype.$backendUrl = 'http://192.168.1.90:9000'
// Vue.prototype.$backendUrlProtocol = 'http'
// Vue.prototype.$backendUrlHost = '192.168.1.90:9000'
// Vue.prototype.$frontendUrl = 'http://192.168.1.90:8080'
// Vue.prototype.$secureCookies = true

// dev
// Vue.prototype.$backendUrl = 'https://transferappsvc-dev.connect.ucf.edu'
// Vue.prototype.$backendUrlProtocol = 'https'
// Vue.prototype.$backendUrlHost = 'transferappsvc-dev.connect.ucf.edu'
// Vue.prototype.$frontendUrl = 'https://transferapp-dev.connect.ucf.edu'
// Vue.prototype.$secureCookies = true

// prod
Vue.prototype.$backendUrl = 'https://transferappsvc.connect.ucf.edu'
Vue.prototype.$backendUrlProtocol = 'https'
Vue.prototype.$backendUrlHost = 'transferappsvc.connect.ucf.edu'
Vue.prototype.$frontendUrl = 'https://transferapp.connect.ucf.edu'
Vue.prototype.$secureCookies = true


Vue.prototype.$axios = axios

Vue.use(cookies);

// set default cookie configuration. 1h = 1 hour
if (Vue.prototype.$secureCookies == true)
  Vue.$cookies.config('1h', '', '', true /* secure */, 'Lax' /* samesite */)
else
  Vue.$cookies.config('1h')

Vue.config.productionTip = false
Vue.use(BootstrapVue)

Vue.use(VueBreadcrumbs)

var app = new Vue({
  router,
  render: h => h(App),
  beforeCreate: function () {
    // console.log("backendUrl = " + this.backendUrl);
  }
});
app = app.$mount('#app')
