import Vue from 'vue'
import VueRouter from 'vue-router'
import MainView from '../views/MainView.vue'
import { EventBus } from '@/event-bus';

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'home', component: () => import(/* webpackChunkName: "mainView" */ '../views/MainView.vue'),
    meta: { breadcrumb: { label: 'Home', } }, },

  { path: '/achievements', name: 'achievements',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "achievements" */ '../views/Achievements.vue'),
    meta: { breadcrumb: { label: 'Achievements', parent: 'home', } },
  },
  { path: '/achievementedit/:id/:new/:count', name: 'achievementEdit', component: () => import(/* webpackChunkName: "achievementEdit" */ '../views/AchievementEdit.vue'),
    meta: { breadcrumb: { label: 'Edit Achievement', parent: 'achievements', } }, },

  { path: '/admin', name: 'admin', component: () => import(/* webpackChunkName: "admin" */ '../views/Admin.vue'),
    meta: { breadcrumb: { label: 'Admin', parent: 'home', } }, },
  { path: '/administration', name: 'administration', component: () => import(/* webpackChunkName: "administration" */ '../views/Administration.vue'),
    meta: { breadcrumb: { label: 'Administration', parent: 'home', } }, },
  { path: '/administrationpilot', name: 'administrationPilot', component: () => import(/* webpackChunkName: "administrationPilot" */ '../views/AdministrationPilot.vue'),
    meta: { breadcrumb: { label: 'Pilot Administration', parent: 'administration', } }, },

  { path: '/partners', name: 'partners', component: () => import(/* webpackChunkName: "partners" */ '../views/Partners.vue'),
    meta: { breadcrumb: { label: 'Partners', parent: 'administration', } }, },
  { path: '/partneredit/:new/:id', name: 'partneredit', component: () => import(/* webpackChunkName: "partneredit" */ '../views/PartnerEdit.vue'),
    meta: { breadcrumb: { label: 'Edit Partner', parent: 'partners', } }, },

  { path: '/banner', name: 'banner', component: () => import(/* webpackChunkName: "banner" */ '../views/Banner.vue'),
    meta: { breadcrumb: { label: 'banner', parent: 'home', } }, },

  { path: '/broadcasts', name: 'broadcasts', component: () => import(/* webpackChunkName: "broadcasts" */ '../views/Broadcasts.vue'),
    meta: { breadcrumb: { label: 'Broadcasts', parent: 'home', } }, },

  { path: '/checklists', name: 'checklists', component: () => import(/* webpackChunkName: "checklists" */ '../views/Checklists.vue'),
    meta: { breadcrumb: { label: 'Checklists', parent: 'home', } }, },
  { path: '/checklistedit/:new/:id', name: 'checklistEdit', component: () => import(/* webpackChunkName: "checklistEdit" */ '../views/ChecklistEdit.vue'),
    meta: { breadcrumb: { label: 'Edit Checklist', parent: 'checklists', } }, },
  { path: '/checklistitems/:checklistid', name: 'checklistItems', component: () => import(/* webpackChunkName: "checklistItems" */ '../views/ChecklistItems.vue'),
    meta: { breadcrumb: { label: 'Checklist Items', parent: 'checklists', } }, },
  { path: '/checklistitemedit/:new/:count/:id/:checklistid', name: 'checklistItemEdit', component: () => import(/* webpackChunkName: "checklistItemEdit" */ '../views/ChecklistItemEdit.vue'),
    meta: { breadcrumb: { label: 'Edit Checklist Item', parent: 'checklistItems', } },
  },

  { path: '/contentlessons', name: 'contentLessons', component: () => import(/* webpackChunkName: "contentLessons" */ '../views/ContentLessons.vue'),
    meta: { breadcrumb: { label: 'Content Lessons', parent: 'home', } }, },
  { path: '/contentlessonedit/:new/:id', name: 'contentLessonEdit', component: () => import(/* webpackChunkName: "contentLessonEdit" */ '../views/ContentLessonEdit.vue'),
    meta: { breadcrumb: { label: 'Edit Content Lesson', parent: 'contentLessons', } }, },
  { path: '/editlessonpages/:lessonid', name: 'editLessonPages', component: () => import(/* webpackChunkName: "editLessonPages" */ '../views/EditLessonPages.vue'),
    meta: { breadcrumb: { label: 'Content Lesson Pages', parent: 'contentLessons', } }, },
  { path: '/contentpageedit/:lessonid/:new/:id/:nextSequence', name: 'contentPageEdit', component: () => import(/* webpackChunkName: "contentPageEdit" */ '../views/ContentPageEdit.vue'),
    meta: { breadcrumb: { label: 'Edit Content Page', parent: 'editLessonPages', } },
  },
  { path: '/editlessonquestions/:lessonid', name: 'editLessonQuestions', component: () => import(/* webpackChunkName: "editLessonQuestions" */ '../views/EditLessonQuestions.vue'),
    meta: { breadcrumb: { label: 'Content Lesson Questions', parent: 'contentLessons', } }, },
  { path: '/contentquestionedit/:lessonid/:new/:id/:nextSequence', name: 'contentQuestionEdit', component: () => import(/* webpackChunkName: "contentQuestionEdit" */ '../views/ContentQuestionEdit.vue'),
    meta: { breadcrumb: { label: 'Edit Content Lesson Question', parent: 'editLessonQuestions', } },
  },
  { path: '/editlessonsurvey/:lessonid', name: 'editLessonSurvey', component: () => import(/* webpackChunkName: "editLessonSurvey" */ '../views/EditLessonSurvey.vue'),
    meta: { breadcrumb: { label: 'Edit Lesson Survey', parent: 'contentLessons', } }, },
  { path: '/contentsurveyquestionedit/:lessonid/:short_description/:new', name: 'contentSurveyQuestionEdit', component: () => import(/* webpackChunkName: "contentSurveyQuestionEdit" */ '../views/ContentSurveyQuestionEdit.vue'),
    meta: { breadcrumb: { label: 'Edit Content Survey Question', parent: 'editLessonSurvey', } },
  },

  { path: '/events', name: 'events', component: () => import(/* webpackChunkName: "events" */ '../views/Events.vue'),
    meta: { breadcrumb: { label: 'Events', parent: 'home', } }, },

  { path: '/levels', name: 'levels', component: () => import(/* webpackChunkName: "levels" */ '../views/Levels.vue'),
    meta: { breadcrumb: { label: 'Levels', parent: 'home', } }, },
  { path: '/leveledit/:new/:count/:id', name: 'levelEdit', component: () => import(/* webpackChunkName: "levelEdit" */ '../views/LevelEdit.vue'),
    meta: { breadcrumb: { label: 'Edit Level', parent: 'levels', } }, },
  { path: '/levelquests/:id', name: 'levelQuests', component: () => import(/* webpackChunkName: "levelQuests" */ '../views/LevelQuests.vue'),
    meta: { breadcrumb: { label: 'Level Quests', parent: 'levels', } }, },
  { path: '/levelquestadd/:id/:nextSequence', name: 'levelQuestAdd', component: () => import(/* webpackChunkName: "levelQuestAdd" */ '../views/LevelQuestAdd.vue'),
    meta: { breadcrumb: { label: 'Add Quest', parent: 'levelQuests', } },
  },

  { path: '/forgotpassword', name: 'forgotPassword', component: () => import(/* webpackChunkName: "forgotPassword" */ '../views/ForgotPassword.vue'),
    meta: { breadcrumb: { label: 'Forgot Password', parent: 'home', } }, },
  { path: '/login', name: 'login', component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    meta: { breadcrumb: { label: 'Login', parent: 'home', } }, },
  { path: '/logout', name: 'logout', component: () => import(/* webpackChunkName: "logout" */ '../views/Logout.vue'),
    meta: { breadcrumb: { label: 'Logout', parent: 'home', } }, },

  { path: '/majors', name: 'majors', component: () => import(/* webpackChunkName: "majors" */ '../views/Majors.vue'),
    meta: { breadcrumb: { label: 'Majors', parent: 'home', } }, },
  { path: '/majoredit/:new/:id', name: 'majorEdit', component: () => import(/* webpackChunkName: "majorEdit" */ '../views/MajorEdit.vue'),
    meta: { breadcrumb: { label: 'Edit Major', parent: 'majors', } }, },
  { path: '/majoritems/:cip', name: 'majorItems', component: () => import(/* webpackChunkName: "majorItems" */ '../views/MajorItems.vue'),
    meta: { breadcrumb: { label: 'Major Items', parent: 'majors', } }, },
  { path: '/majoritemedit/:cip/:new/:id', name: 'majorItemEdit', component: () => import(/* webpackChunkName: "majorItemEdit" */ '../views/MajorItemEdit.vue'),
    meta: { breadcrumb: { label: 'Edit Major Item', parent: 'majorItems', } },
  },

  { path: '/media', name: 'media', component: () => import(/* webpackChunkName: "media" */ '../views/Media.vue'),
    meta: { breadcrumb: { label: 'Media', parent: 'home', } }, },
  { path: '/mediausage/:mediaid', name: 'mediaUsage', component: () => import(/* webpackChunkName: "mediaUsage" */ '../views/MediaUsage.vue'),
    meta: { breadcrumb: { label: 'Media Usage', parent: 'media', } }, },

  { path: '/pois', name: 'pois', component: () => import(/* webpackChunkName: "pois" */ '../views/POIs.vue'),
    meta: { breadcrumb: { label: 'Points of Interest', parent: 'home', } }, },
  { path: '/poiedit/:new/:id', name: 'poiedit', component: () => import(/* webpackChunkName: "poiedit" */ '../views/POIEdit.vue'),
    meta: { breadcrumb: { label: 'Edit Point of Interest', parent: 'pois', } }, },

  { path: '/preview/:id', name: 'preview', component: () => import(/* webpackChunkName: "preview" */ '../views/Preview.vue'),
    meta: { breadcrumb: { label: 'Preview', parent: 'home', } }, },
  { path: '/profile', name: 'profile', component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue'),
    meta: { breadcrumb: { label: 'Profile', parent: 'home', } }, },

  { path: '/quests', name: 'quests', component: () => import(/* webpackChunkName: "quests" */ '../views/Quests.vue'),
    meta: { breadcrumb: { label: 'Quests', parent: 'home', } }, },
  { path: '/questedit/:new/:id', name: 'questEdit', component: () => import(/* webpackChunkName: "questEdit" */ '../views/QuestEdit.vue'),
    meta: { breadcrumb: { label: 'Edit Quest', parent: 'quests', } }, },

  { path: '/workshops', name: 'workshops', component: () => import(/* webpackChunkName: "workshops" */ '../views/Workshops.vue'),
    meta: { breadcrumb: { label: 'Workshops', parent: 'home', } }, },
  { path: '/workshopedit/:new/:id', name: 'workshopEdit', component: () => import(/* webpackChunkName: "workshopEdit" */ '../views/WorkshopEdit.vue'),
    meta: { breadcrumb: { label: 'Edit Workshop', parent: 'workshops', } }, },

  { path: '/reports', name: 'reports', component: () => import(/* webpackChunkName: "reports" */ '../views/Reports.vue'),
    meta: { breadcrumb: { label: 'Reports', parent: 'home', } }, },

  { path: '/scripts', name: 'scripts', component: () => import(/* webpackChunkName: "scripts" */ '../views/Scripts.vue'),
    meta: { breadcrumb: { label: 'Scripts', parent: 'home', } }, },
  { path: '/scriptedit/:new/:id', name: 'scriptEdit', component: () => import(/* webpackChunkName: "scriptEdit" */ '../views/ScriptEdit.vue'),
    meta: { breadcrumb: { label: 'Edit Script', parent: 'scripts', } }, },
  { path: '/scriptsteps/:scriptid', name: 'scriptSteps', component: () => import(/* webpackChunkName: "scriptSteps" */ '../views/ScriptSteps.vue'),
    meta: { breadcrumb: { label: 'Script Steps', parent: 'scripts', } }, },
  { path: '/scriptstepedit/:new/:id/:count/:scriptid', name: 'scriptStepEdit', component: () => import(/* webpackChunkName: "scriptStepEdit" */ '../views/ScriptStepEdit.vue'),
    meta: { breadcrumb: { label: 'Edit Script Step', parent: 'scriptSteps', } },
  },

  { path: '/setpassword', name: 'setPassword', component: () => import(/* webpackChunkName: "setPassword" */ '../views/SetPassword.vue'),
    meta: { breadcrumb: { label: 'Set Password', parent: 'home', } }, },
  { path: '/settings', name: 'settings', component: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue'),
    meta: { breadcrumb: { label: 'Settings', parent: 'home', } }, },

  { path: '/students', name: 'students', component: () => import(/* webpackChunkName: "students" */ '../views/Students.vue'),
    meta: { breadcrumb: { label: 'Students', parent: 'home', } }, },
  { path: '/studentedit/:id', name: 'studentEdit', component: () => import(/* webpackChunkName: "studentEdit" */ '../views/StudentEdit.vue'),
    meta: { breadcrumb: { label: 'Student Edit', parent: 'students', } }, },
  { path: '/studentmessages/:studentid', name: 'studentMessages', component: () => import(/* webpackChunkName: "studentMessages" */ '../views/StudentMessages.vue'),
    meta: { breadcrumb: { label: 'Student Messages', parent: 'students', } }, },
  { path: '/studentachievements/:studentid', name: 'studentAchievements', component: () => import(/* webpackChunkName: "studentAchievements" */ '../views/StudentAchievements.vue'),
    meta: { breadcrumb: { label: 'Student Achievements', parent: 'students', } }, },

  { path: '/users', name: 'users', component: () => import(/* webpackChunkName: "users" */ '../views/Users.vue'),
    meta: { breadcrumb: { label: 'Users', parent: 'home', } }, },
  { path: '/verify', name: 'verify', component: () => import(/* webpackChunkName: "verify" */ '../views/Verify.vue'),
    meta: { breadcrumb: { label: 'Verify', parent: 'home', } }, },

  { path: '/:catchAll(.*)', name: 'NotFound', component: () => import(/* webpackChunkName: "NotFound" */ '../views/NotFound.vue'),},
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // Redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login', '/preview', '/verify', '/setPassword', '/forgotPassword'];
  let authRequired = !publicPages.includes(to.path);

  // renew cookies on user activity if not expired
  if (authRequired && Vue.$cookies.get('api_token') != null) {
    EventBus.$emit('extend_timeout', {});
  }

  if (authRequired && Vue.$cookies.get('api_token') == null) {

    EventBus.$emit('logout_occurred', {}); // this refreshes the menu

    var timeout = 'yes';
    if (to.path == '/')
      timeout = null;

    return next({
      path: '/login',
      query: { timeout: timeout },
    });
  }

  // var today = new Date();
  // var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  // console.log('current time: ' + time);

  next();
});

export default router
