<template>
  <div id="app">
    <Header/>
    <br/>
    <br/>
    <Breadcrumbs v-if="this.isSetPassword == false"/>
    <router-view/>
    <br/>
    <br/>
    <Footer/>
  </div>
</template>

<script>

import Header from '@/Header.vue'
import Footer from '@/Footer.vue'
import Vue from 'vue'

export default {
  name: 'App',
  components: {
    Header,
    Footer
  },
  data () {
			return {
				isSetPassword: false,
			}
		},
		mounted () {
      this.isSetPassword = window.location.pathname === '/setPassword';
    }
};

</script>

<style>

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
